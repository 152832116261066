import React from 'react';
import { PROJECT_TEMPLATE_IDS } from '../../../common/constants/templateIds';
import { SURVEY_TYPE } from '../../../common/constants/surveyType';

const DEFAULT_TOOLS_CONTENT = {
  Employee: {
    default: {
      title: 'Employee',
      content: <div>Employee Info</div>,
    },
  },
  Vendor: {
    default: {
      title: 'Supplier',
      content: <div>Vendor Info</div>,
    },
  },
  Project: {
    default: {
      title: 'Projects',
      content: <p>Projects info</p>,
    },
  },
  Activity: {
    default: {
      title: 'Activity',
      content: <div>Activity Info</div>,
    },
  },
  ProjectAllocation: {
    default: {
      title: 'Project allocation',
      content: <div>Project Allocation Info</div>,
    },
  },
  ActivityAllocation: {
    default: {
      title: 'Activity allocation',
      content: <div>Activity Allocation Info</div>,
    },
  },
  Submit: {
    default: {
      title: 'Submit Attestation',
      content: <div>Submit Attestation</div>,
    },
  },
};

export const TOOLS_CONTENT_FN = templateId => {
  if (!PROJECT_TEMPLATE_IDS.includes(templateId)) {
    return {
      ...DEFAULT_TOOLS_CONTENT,
      ProjectDocuments: {
        default: {
          title: 'Projects documents',
          content: <p>Projects Documents info</p>,
        },
      },
    };
  }
  return DEFAULT_TOOLS_CONTENT;
};

export const descriptions = (stateKey, year, surveyType) => {
  const descriptionKey = `${stateKey}_${surveyType}`;
  switch (descriptionKey) {
    case 'Project_Labor':
      return `Select all projects you and/or your employees worked on in ${year}.
         You can add project(s) if you cannot find your team's project(s) in the list.`;

    case 'ProjectsDocument_Labor':
      return (
        'It is recommended that you provide documentation to support employee involvement' +
        ' in the projects ' +
        'reported in the survey. Retaining documentation to support the support the rep' +
        'orted is extremely importa' +
        'nt. Generally, it is necessary to retain documentation for at least seven years.' +
        ' Please keep copies of al' +
        'l the documents included on this list in secure storage. Based on your responses,' +
        ' the Tax Department ' +
        'may contact you in the future to obtain additional documentaion.'
      );

    case 'Activity_Labor':
      return `Select all activities performed by you and/or your employee(s) in ${year}.`;

    case 'ProjectAllocation_Labor':
      return `Enter the percentage of time each employee spent on the project(s). 
      You must allocate 100% of each employee's time for the survey to be considered complete.
      Alternatively, download the matrix, fill it out offline and upload back. 
      <strong>Do not change
      the format of downloaded file.</strong>`;

    case 'ActivityAllocation_Labor':
      return `Enter the percentage of time each employee spent on the activity. 
      You must allocate 100% of each employee's time for the survey to be considered complete.
      Alternatively, download the matrix, fill it out offline and upload back. 
      <strong>Do not change
      the format of downloaded file.</strong>`;

    case 'ProjectDocuments_Labor':
      return `It is recommended that you provide documentation to support the employee 
      involvement in the projects reported in the survey. Retaining documentation to 
      support the reported is extremely important. Generally, it is necessary to retain 
      documentation for at least seven years. Please keep copies of all the documents 
      included on this list in secure storage. Based on your responses, the Tax Department 
      may contact you in the future to obtain additional documentation.`;

    case 'Project_Contract':
      return `Select all projects suppliers worked on in ${year}.
         You can add project(s) if you cannot find in the list.`;

    case 'Activity_Contract':
      return `Select all activities suppliers worked on in ${year}.
         You can add activities if you cannot find in the list.`;

    case 'ProjectAllocation_Contract':
      return (
        `Allocate the percentage of supplier expense to the project(s). 
      You must allocate 100% of each supplier expense for the survey to be considered complete. ` +
        `Alternatively, download this matrix, fill it offline, and upload back. Do not change the 
      format of the downloaded file`
      );

    case 'ActivityAllocation_Contract':
      return (
        `Allocate the percentage of supplier expense to the activity. 
      You must allocate 100% of each supplier expense for the survey to be considered complete. ` +
        `Alternatively, download this matrix, fill it offline, and upload back. Do not change the 
      format of the downloaded file`
      );

    default:
      return null;
  }
};

export const initialState = {
  Employee: {},
  Project: {},
  ProjectDocuments: {
    data: {},
    error: null,
  },
  Activity: {},
  ProjectAllocation: {
    data: {},
    error: null,
    errorMessage: null,
  },
  ActivityAllocation: {
    data: {},
    error: null,
    errorMessage: null,
  },
  Submit: {
    data: [],
    textbox: '',
    error: null,
  },
  activeStep: 0,
  SubmitAPI: {
    response: null,
    error: null,
  },
};

export const validationInitialState = {
  projectAllocationState: {
    modalVisible: false,
    projectName: null,
    projectId: null,
    projectRemoved: false,
  },
  activityAllocationState: {
    modalVisible: false,
    activityName: null,
    activityId: null,
    activityRemoved: false,
  },
};

export const apiResponses = {
  Employee: {
    employeeList: [],
  },
  Project: {},
  ProjectDocuments: {},
  Activity: {},
  ProjectAllocation: {},
  ActivityAllocation: {},
  Submit: {},
  SecondaryAssignees: [],
  Vendor: {
    vendorList: [],
  },
};

export const errorMessages = {
  [SURVEY_TYPE.LABOR]: {
    Project: 'You must select at least one project.',
    ActivityAllocation:
      'You must allocate a total of 100% to each employee in Activity allocation.',
    ProjectAllocation: 'You must allocate a total of 100% to each employee in Project allocation.',
    Submit: 'You must select one checkbox',
    EntityNames: 'You must enter at least one individual name whom you consulted with.',
  },
  [SURVEY_TYPE.CONTRACT]: {
    Project: 'You must select at least one project.',
    ActivityAllocation:
      'You must allocate a total of 100% to each supplier in Activity allocation.',
    ProjectAllocation: 'You must allocate a total of 100% to each supplier in Project allocation.',
    Submit: 'You must select one checkbox',
    EntityNames: 'You must enter at least one individual name whom you consulted with.',
  },
};

export const INFO_LINK = 'https://w.amazon.com/bin/view/US_RD_Tax_Credit/';

export const AMZN = 'AMZN';
export const COMID = 'COMID';
export const ZOOID = 'ZOOID';
