import debounce from '@amzn/awsui-components-react/polaris/internal/debounce';
import { Select } from '@amzn/awsui-components-react';
import React from 'react';
import { get, isEmpty, isNull, map } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  DEFAULT_BUSINESS_ID,
  DEFAULT_COUNTRY,
  ITEM_TYPE_LABOR,
} from '../../../../common/config/api_constants';
import { searchPopulationActions } from '../../redux/population';
import { getStudyPeriod } from '../../../../common/constants/study_period';

const SearchPopulationDropdown = ({
  state,
  setState,
  data,
  getDataBegin,
  isFetchingData,
  placeholder,
  studyPeriod,
  isReadOnly,
  clearData,
}) => {
  const setSMEOption = value => {
    setState({ ...state, selectedSME: value });
    clearData();
  };

  const getMappedSMEs = list => {
    return map(list, ({ formattedEmployeeName, employeeAlias, jobTitle, recordId }) => {
      return {
        label: formattedEmployeeName,
        description: employeeAlias,
        tags: [jobTitle],
        recordId,
      };
    });
  };

  const getSMEs = () => {
    return getMappedSMEs(get(data, '1', []));
  };

  const getQueryString = filteringText => {
    if (isEmpty(filteringText)) return '';

    return `employeeAlias equals ${filteringText} || employeeAlias contains ${filteringText}`;
  };

  const fetchData = filteringText => {
    getDataBegin({
      body: {
        clientId: DEFAULT_BUSINESS_ID,
        year: studyPeriod,
        country: DEFAULT_COUNTRY,
        itemType: ITEM_TYPE_LABOR,
        pageSize: 10,
        pageNumber: 1,
        query: getQueryString(filteringText),
      },
    });
  };
  const getStatus = () => {
    if (isFetchingData) {
      return 'loading';
    }
    return 'finished';
  };

  const handleLoadItems = ({ detail: { filteringText } }) => {
    if (state.filterText === filteringText) return;
    setState({ ...state, filterText: filteringText });
    if (isEmpty(filteringText) || isNull(filteringText)) {
      clearData();
      return;
    }
    fetchData(filteringText);
  };

  return (
    <Select
      selectedOption={get(state, 'selectedSME', '')}
      onChange={({ detail }) => setSMEOption(detail.selectedOption)}
      statusType={getStatus()}
      onLoadItems={debounce(handleLoadItems, 1000)}
      loadingText="Loading SMEs"
      options={getSMEs()}
      filteringType="auto"
      selectedAriaLabel="Selected"
      errorText="Error fetching results."
      placeholder={placeholder}
      disabled={isReadOnly}
      onBlur={clearData}
    />
  );
};

SearchPopulationDropdown.propTypes = {
  isFetchingData: PropTypes.bool.isRequired,
  getDataBegin: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  studyPeriod: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  clearData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  data: get(state, 'entities.population.data', {}) || {},
  isFetchingData: get(state, 'entities.population.isFetching', false) || false,
  studyPeriod: getStudyPeriod(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataBegin: searchPopulationActions.BEGIN,
      clearData: searchPopulationActions.CLEAR,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchPopulationDropdown);
