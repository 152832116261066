import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi';

const KATAL_METRICS_REGION = 'USAmazon';
const KATAL_METRICS_SITE_NAME = 'ValariSurveyWebsite';

const metricsErrorHandler = err => {
  console.error(err);
};

const createMetricsContextForPage = (pageName, relatedMetrics) => {
  return new KatalMetrics.Context.Builder()
    .withSite(KATAL_METRICS_SITE_NAME)
    .withRelatedMetrics(relatedMetrics)
    .withServiceName(pageName)
    .build();
};

export const createMetricsDriver = () => {
  return new KatalMetricsDriverSushi.Builder()
    .withDomainRealm(process.env.REACT_APP_KATAL_METRICS_ENVIRONMENT, KATAL_METRICS_REGION)
    .withErrorHandler(metricsErrorHandler)
    .build();
};

export const createMetricsPublisherForContext = (metricsDriver, pageName, relatedMetrics) => {
  return new KatalMetrics.Publisher(
    metricsDriver,
    metricsErrorHandler,
    createMetricsContextForPage(pageName, relatedMetrics),
  );
};

export const createMetricsPublisherForMethod = (metricsPublisher, methodName) => {
  return metricsPublisher.newChildActionPublisherForMethod(methodName);
};

export const createStringMetric = (name, value) => {
  return new KatalMetrics.Metric.String(name, value);
};

export const createTimerStopwatchMetric = name => {
  return new KatalMetrics.Metric.TimerStopwatch(name).withMonitor();
};
