import React, { useEffect, useState } from 'react';

import {
  Input,
  Container,
  Header,
  SpaceBetween,
  Button,
  Icon,
  Popover,
} from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import './AllocationGrid.css';
import * as XLSX from 'xlsx';
import { round } from 'lodash/math';
import { isNaN, size } from 'lodash';
import exportToCSV from './utils';
import {
  getAllocationErrorMessage,
  getEntityDetailsKey,
  getSecondaryTitle,
  SURVEY_TYPE,
} from '../../../../common/constants/surveyType';
import { isReadOnly } from '../../utils/survey_page_utils';

const AllocationGrid = props => {
  const {
    rowsEntity: projects,
    colsEntity: entityDetails,
    gridValues,
    onAllocationChange,
    uploadedData,
    title,
    titleEnitity,
    selectEntity,
    isDownloadUploadButtonDisabled,
    allocationType,
    surveyType,
    surveyDetails,
  } = props;

  const excelData = [];
  const heading = allocationType === 'Project' ? ['Project ID', 'Project Name'] : ['Activity Name'];
  const entityCountInitialState = {
    prevIndex: 0,
    nextIndex: 0,
  };
  const [entityCountState, setEntityCountState] = useState(entityCountInitialState);
  useEffect(() => {
    setEntityCountState(prevState => {
      return {
        ...prevState,
        nextIndex:
          // eslint-disable-next-line no-nested-ternary
          size(entityDetails) > 3
            ? prevState.nextIndex === 0
              ? 3
              : prevState.nextIndex
            : size(entityDetails),
      };
    });
  }, [entityDetails]);

  entityDetails.forEach(e => {
    const key = getEntityDetailsKey(surveyType, e);
    heading.push(key);
  });

  const onBlurHandler = (emp, project) => {
    onAllocationChange(
      round(Number(gridValues[emp.id][project.id] || 0), 1).toString(),
      project.id,
      emp.id,
    );
  };
  const onInputChangeHandler = (val, subordinate, project, inputBoxId) => {
    const inputElement = document.getElementById(inputBoxId).childNodes[0];
    if (Number(val) > 100) {
      inputElement.style.borderColor = 'red';
    } else {
      inputElement.style.borderColor = '#aab7b8';
    }
    onAllocationChange(val, project.id, subordinate.id);
  };
  const isLeftScrollButtonDisabled = () => {
    return entityCountState.prevIndex === 0;
  };
  const isRightScrollButtonDisabled = () => {
    return entityCountState.nextIndex === size(entityDetails);
  };
  const scrollRight = () => {
    setEntityCountState(prevState => {
      return {
        prevIndex: prevState.nextIndex,
        nextIndex:
          prevState.nextIndex + 3 > size(entityDetails)
            ? size(entityDetails)
            : prevState.nextIndex + 3,
      };
    });
  };
  const scrollLeft = () => {
    setEntityCountState(prevState => {
      return {
        prevIndex: prevState.prevIndex - 3,
        nextIndex: prevState.prevIndex,
      };
    });
  };
  const renderColumn = (subordinates, project, isFirstRow, isLastRow) => {
    const content = [];
    for (let i = entityCountState.prevIndex; i < entityCountState.nextIndex; i += 1) {
      const total = (gridValues[subordinates[i].id] && gridValues[subordinates[i].id].total) || '';
      const val =
        (gridValues[subordinates[i].id] && gridValues[subordinates[i].id][project.id]) || '';
      const inputBoxId = `${subordinates[i].id}#${project.id}`;
      content.push(
        <div
          key={subordinates[i].id}
          className={isLastRow && Number(total) !== 100 ? 'invalid row-emp' : 'row-emp'}
        >
          {isFirstRow && (
            <div>
              <div className="primary-title">{subordinates[i].name}</div>
              <div className="secondaryTitle">{getSecondaryTitle(surveyType, subordinates[i])}</div>
              <div className="secondaryTitle">
                {surveyType === SURVEY_TYPE.CONTRACT
                  ? `$${subordinates[i].amount.toLocaleString('en-US')}`
                  : ''}
              </div>
            </div>
          )}
          {!isFirstRow && !isLastRow && (
            <Popover
              dismissButton={false}
              position="right"
              size="small"
              triggerType="custom"
              header={project.name}
              content={project.description}
            >
              <Input
                key={subordinates[i].id + project.id}
                title="This is the text of the tooltip"
                className={i % 2 === 0 ? 'even-row' : 'odd-row'}
                type="text"
                value={val}
                id={inputBoxId}
                style={{ borderColor: 'red' }}
                onChange={({ detail }) =>
                  onInputChangeHandler(detail.value, subordinates[i], project, inputBoxId)
                }
                disabled={isReadOnly(surveyDetails)}
                onBlur={() => onBlurHandler(subordinates[i], project)}
              />
            </Popover>
          )}
          {isLastRow && total && Number(total) !== 100 && (
            <Icon name="status-warning" size="small" variant="error" />
          )}
          {isLastRow && total && ` ${total}%`}
        </div>,
      );
      if (val > 100) {
        const inputElement = document.getElementById(inputBoxId);
        if (inputElement) {
          inputElement.childNodes[0].style.borderColor = 'red';
        }
      }
    }
    return content;
  };

  const renderRow = (project, subordinates, idx, isFirstRow, isLastRow, isNavigationButton) => {
    const obj = {};

    if (allocationType === 'Project') obj.projectId = project.id;

    obj.projectName = project.name || '';

    if (!isFirstRow) {
      subordinates.forEach(subordinate => {
        const total = (gridValues[subordinate.id] && gridValues[subordinate.id].total) || '';
        const val = (gridValues[subordinate.id] && gridValues[subordinate.id][project.id]) || '';

        const key = getEntityDetailsKey(surveyType, subordinate);

        obj[key] = isLastRow ? total : val;
      });
      if (!isLastRow) excelData.push(obj);
    }

    return (
      <div className={idx % 2 === 0 ? 'even-row row ' : 'old-row row'}>
        <div className="project-side">
          <div className="project-title">
            {project.name || <div className="project-header"></div>}
          </div>
        </div>
        <div className="emp-count-left">
          {isFirstRow ? (
            <div className="count">
              (
              <b>
                {entityCountState.prevIndex / 3 + 1}/{round((size(entityDetails) + 1) / 3, 0)}
              </b>
              )
            </div>
          ) : (
            ''
          )}
          {isNavigationButton ? (
            <div className="emp-scroll-left">
              <Button
                variant="icon"
                iconName="angle-left"
                disabled={isLeftScrollButtonDisabled()}
                onClick={scrollLeft}
              ></Button>
            </div>
          ) : (
            ''
          )}
        </div>
        {renderColumn(subordinates, project, isFirstRow, isLastRow)}
        {isNavigationButton ? (
          <div className="emp-scroll-right">
            <Button
              variant="icon"
              iconName="angle-right"
              disabled={isRightScrollButtonDisabled()}
              onClick={scrollRight}
            ></Button>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  /* eslint-disable */
  const prepareData = excelData => {
    let gridData = {};
    for (let data of excelData) {
      let projectId = '';
      let entityId = '';
      let value = '';

      if (Object.keys(data).includes(heading[0])) {
        for (let key in data) {
          if (key === heading[0] && allocationType == 'Project') {
            projectId = getProjectId(data[key]);
            if (projectId == -1)
              return {
                errorMessage:
                  'Project(s) does not match with project(s) selected on Project page. Add, edit or select project in the Project page, and download the matrix again',
                status: 'error',
              };
          } else if (key === heading[0] && allocationType == 'Activity') {
            projectId = getProjectFromName(data[key]);
            if (projectId == -1)
              return {
                errorMessage:
                  'Activity does not match with activity selected on Activity page. Select activity in the Activity page, and download the matrix again.',
                status: 'error',
              };
          } else if (!key.startsWith('Project') && !key.startsWith('Activity')) {
            entityId = getEntityId(key);
            if (entityId == -1)
              return {
                errorMessage: getAllocationErrorMessage(surveyType),
                status: 'error',
              };
            value = Number(data[key]);
            if (isNaN(value) || value < 0)
              return {
                errorMessage:
                  'Invalid time allocation input. Enter only numbers (no special characters)',
                status: 'error',
              };
            gridData[entityId] = gridData[entityId] || {};
            gridData[entityId][projectId] = round(Number(value), 1).toString();
          }
        }
      }
    }
    return calculateTotal(gridData);
  };

  const parseExcel = file => {
    const reader = new FileReader();

    reader.onload = function load(e) {
      const data = e.target.result;
      const workbook = XLSX.read(data, {
        type: 'binary',
      });

      const xlRowObject = XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets[workbook.SheetNames[0]],
      );
      const parsedObject = JSON.stringify(xlRowObject);
      const dataImported = prepareData(JSON.parse(parsedObject));
      uploadedData(dataImported);
    };

    reader.readAsBinaryString(file);
  };

  const calculateTotal = gridData => {
    for (let entity in gridData) {
      let total = 0;
      for (let project in gridData[entity]) {
        if (project != 'total') total += parseFloat(gridData[entity][project]);
      }
      gridData[entity]['total'] = total.toFixed(1);
    }
    return gridData;
  };

  const getEntityId = entityNameFromExcel => {
    let entity = entityDetails.find(
      e => getEntityDetailsKey(surveyType, e) === entityNameFromExcel,
    );
    return entity ? entity.id : -1;
  };

  const getProjectId = projectId => {
    let project = projects.find(project => project.id === projectId);
    return project ? project.id : -1;
  };

  const getProjectFromName = projectName => {
    let project = projects.find(project => project.name === projectName);
    return project ? project.id : -1;
  };

  const renderGrid = () => {
    const totalProjects = size(projects);
    const i = round(totalProjects / 2, 0);
    return projects.map((project, index) => {
      return renderRow(project, entityDetails, index, null, null, index === i - 1);
    });
  };

  const handleFileSelected = event => {
    const files = event.target.files;
    const result = parseExcel(files[0]);
    event.target.value = null;
    return result;
  };

  return (
    <Container
      className="allocation-container"
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                disabled={isDownloadUploadButtonDisabled}
                onClick={() => exportToCSV(heading, excelData, title, allocationType)}
              >
                Download Matrix
              </Button>
              <Button
                disabled={isDownloadUploadButtonDisabled || isReadOnly(surveyDetails)}
                className="polarisInputButton"
              >
                <input
                  className="inputButton hidden"
                  id="file-upload"
                  type="file"
                  onChange={handleFileSelected}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                Upload Matrix
              </Button>
            </SpaceBetween>
          }
        >
          {title}
        </Header>
      }
    >
      <div>
        <div className="container">
          {renderRow({}, entityDetails, 1, true, null, null)}

          {renderGrid()}
          {projects.length > 0 ? (
            renderRow({ name: 'Total' }, entityDetails, 1, null, true, null)
          ) : (
            <div className="center">
              <p> You don't have any {titleEnitity} </p>
              <Button onClick={selectEntity} disabled={isReadOnly(surveyDetails)}>
                Select {titleEnitity}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

AllocationGrid.propTypes = {
  rowsEntity: PropTypes.array.isRequired,
  colsEntity: PropTypes.array.isRequired,
  gridValues: PropTypes.object.isRequired,
  onAllocationChange: PropTypes.func.isRequired,
  uploadedData: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titleEnitity: PropTypes.string.isRequired,
  selectEntity: PropTypes.func.isRequired,
  isDownloadUploadButtonDisabled: PropTypes.bool,
  allocationType: PropTypes.string.isRequired,
  surveyType: PropTypes.string.isRequired,
  surveyDetails: PropTypes.object.isRequired,
};

AllocationGrid.defaultProps = {
  isDownloadUploadButtonDisabled: false,
};

export default AllocationGrid;
