import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { get, isNull, isUndefined } from 'lodash';
import { getEntityListPath } from '../../../../common/constants/surveyType';

function fitToColumn(heading, apiData, allocatinType) {
  // get maximum character of each column
  if (apiData.length <= 0) return [];

  if (allocatinType === 'Project') {
    let projectId = heading[0].length;
    let project = heading[1].length;
    apiData.forEach(data => {
      projectId = Math.max(projectId, data.projectId.toString().length);
      project = Math.max(project, data.projectName.toString().length);
    });
    const titles = Object.keys(apiData[0])
      .filter(key => !key.startsWith('project'))
      .map(key => {
        return { wch: key.toString().length };
      });
    return [{ wch: projectId }, { wch: project }, ...titles];
  }

  let project = heading[0].length;
  apiData.forEach(data => {
    project = Math.max(project, data.projectName.toString().length);
  });
  const titles = Object.keys(apiData[0])
    .filter(key => !key.startsWith('project'))
    .map(key => {
      return { wch: key.toString().length };
    });
  return [{ wch: project }, ...titles];
}

const exportToCSV = (heading, apiData, fileName, allocationType) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const ws = XLSX.utils.book_new();
  ws['!cols'] = fitToColumn(heading, apiData, allocationType);

  XLSX.utils.sheet_add_aoa(ws, [heading]);
  XLSX.utils.sheet_add_json(ws, apiData, { origin: 'A2', skipHeader: true });

  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export default exportToCSV;

const enrichEntityMapForAllocation = (entities, subordinates, allocations, entityMap) => {
  if (!isNull(entities) && !isUndefined(entities)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const entity of entities) {
      let allocation = -1;
      // eslint-disable-next-line no-restricted-syntax
      for (const subordinate of subordinates) {
        allocation = Number(get(allocations[subordinate.recordId], [entity.id], -1));
        if (allocation !== -1 && allocation !== 0) {
          entityMap.set(entity.id, true);
          break;
        }
      }
    }
  }
};

export const isProjectHasNoAllocation = ({
  surveyInfo,
  pageElements,
  setValidationState,
  surveyType,
}) => {
  const subordinates = get(pageElements, getEntityListPath(surveyType), {});
  const projects = surveyInfo.Project.data.selectedProjects;
  const projectAllocations = surveyInfo.ProjectAllocation.data;
  const projectMap = new Map();
  enrichEntityMapForAllocation(projects, subordinates, projectAllocations, projectMap);
  if (!isNull(projects) && !isUndefined(projects)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const project of projects) {
      if (!projectMap.has(project.id)) {
        setValidationState(prevState => {
          return {
            ...prevState,
            projectAllocationState: {
              modalVisible: true,
              projectName: project.name,
              projectId: project.id,
              projectRemoved: false,
            },
          };
        });
        return false;
      }
    }
  }
  return true;
};

const isActivityPresentInActivityMap = (activities, activityMap, setValidationState) => {
  if (!isNull(activities) && !isUndefined(activities)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const activity of activities) {
      if (!activityMap.has(activity.id)) {
        setValidationState(prevState => {
          return {
            ...prevState,
            activityAllocationState: {
              modalVisible: true,
              activityName: activity.name,
              activityId: activity.id,
              activityRemoved: false,
            },
          };
        });
        return false;
      }
    }
  }
  return true;
};

export const isActivityHasNoAllocation = ({
  surveyInfo,
  pageElements,
  setValidationState,
  surveyType,
}) => {
  const subordinates = get(pageElements, getEntityListPath(surveyType), {});
  const { selectedActivities } = surveyInfo.Activity.data;
  const activityAllocation = surveyInfo.ActivityAllocation.data;
  const activityMap = new Map();
  enrichEntityMapForAllocation(selectedActivities, subordinates, activityAllocation, activityMap);
  return isActivityPresentInActivityMap(selectedActivities, activityMap, setValidationState);
};
