import React, { useState, useEffect } from 'react';
import { Box, SpaceBetween, Modal, Button } from '@amzn/awsui-components-react/polaris';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.cjs';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import SURVEY_LOCK_ACTIONS from '../../../../common/constants/survey_lock_actions';
import { saveSurveyActions } from '../../redux/saveSurvey';
import { updateSurveyLockAction } from '../../../landing/common/redux/UpdateSurveyLock';

function msToRemainingTime(duration) {
  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;
  return `${minutes}:${seconds}`;
}

/*
 * Component for detecting and acting upon inactivity in a survey
 */
const SurveyInactivityTimer = ({
  isReadOnly,
  surveyPayload,
  updateSurveyLockData,
  saveSurvey,
  updateSurveyLock,
}) => {
  const autoSaveAndSignOut = () => {
    if (!isReadOnly) {
      saveSurvey({
        onSuccess: () => {
          updateSurveyLock({
            onSuccess: () => {
              Auth.signOut();
            },
            body: {
              surveyId: surveyPayload.surveyId,
              actionType: SURVEY_LOCK_ACTIONS.Release,
              sessionId: updateSurveyLockData.sessionId,
            },
          });
        },
        body: surveyPayload,
      });
    } else {
      Auth.signOut();
    }
  };
  const [timeToIdle, setTimeToIdle] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const timer = useIdleTimer({
    onIdle: () => {
      autoSaveAndSignOut();
      setShowPrompt(false);
    },
    onPrompt: () => {
      setShowPrompt(true);
    },
    timeout: 600000,
    promptBeforeIdle: 120000,
    stopOnIdle: true,
  });
  useEffect(() => {
    setInterval(() => {
      setTimeToIdle(timer.getRemainingTime());
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal
      onDismiss={() => {
        timer.reset();
        setShowPrompt(false);
      }}
      visible={showPrompt}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={autoSaveAndSignOut}>
              Sign out
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                timer.reset();
                setShowPrompt(false);
              }}
            >
              Continue
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Your session is about to expire"
    >
      This session will expire in {msToRemainingTime(timeToIdle)} due to inactivity. If you want to
      extend your session, select the ‘Continue’ button. If you select the ‘Sign out’ button or do
      not respond, your session will automatically close.
    </Modal>
  );
};

SurveyInactivityTimer.propTypes = {
  surveyPayload: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  updateSurveyLockData: PropTypes.object.isRequired,
  saveSurvey: PropTypes.func.isRequired,
  updateSurveyLock: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators(
    {
      saveSurvey: saveSurveyActions.BEGIN,
      updateSurveyLock: updateSurveyLockAction.BEGIN,
    },
    dispatch,
  );
  return {
    ...actions,
    navigateTo: payload => dispatch(push(payload)),
  };
};

export default connect(null, mapDispatchToProps)(SurveyInactivityTimer);
