import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import addContextToPayload from '../../../../common/utils/api_util';
import { GET_SURVEY_DETAILS_ENDPOINT, VALARI_API } from '../../../../common/config/api_endpoints';
import { getStudyPeriod } from '../../../../common/constants/study_period';
import { updateSurveyLockAction } from '../../../landing/common/redux/UpdateSurveyLock';
import SURVEY_LOCK_ACTIONS from '../../../../common/constants/survey_lock_actions';

/**
 * Custom Session Extension hook that is responsible for extending the backend session for the user
 * when they reach the end of the existing session. This ensures that an active user is never logged
 * out abruptly when the backend session times out.
 */

export default function useSessionExtension() {
  const dispatch = useDispatch();

  // We perform session extension 15 seconds before the end of current session
  const LOCK_EXTENSION_CALL_BUFFER_IN_MS = 15000;

  // Timestamp till which the timer is running
  const previousLockUntil = useRef(0);

  // Denotes whether lock details need to be fetched from the backend or not
  const shouldFetchLockDetails = useRef(true);

  // Variable to store the latest lock details for survey fetch from the backend
  const [lockAcquiredUntil, setLockAcquiredUntil] = useState(0);

  const surveyId = useSelector(state => get(state, 'router.location.state.headerDetails.surveyId'));
  const updateSurveyLockData = useSelector(state =>
    get(state, 'entities.updateSurveyLock.data.body', {}),
  );
  const studyPeriod = useSelector(state => getStudyPeriod(state));

  // Function to fetch latest lock details for survey
  const fetchLatestLockDetails = () => {
    addContextToPayload(VALARI_API, GET_SURVEY_DETAILS_ENDPOINT, studyPeriod, {
      body: {
        surveyId,
      },
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        if (response.body.surveyLockDetails && response.body.surveyLockDetails.lockAcquiredUntil) {
          setLockAcquiredUntil(parseInt(response.body.surveyLockDetails.lockAcquiredUntil, 10));
        }
      })
      .catch(e => {
        throw e;
      });
  };

  // Effect to fetch Lock Details on first render and henceforth every time session is extended.
  useEffect(() => {
    if (shouldFetchLockDetails) {
      fetchLatestLockDetails(surveyId);
      shouldFetchLockDetails.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchLockDetails]);

  // Effect to trigger a timer on first render and henceforth every time the timer gets finished
  useEffect(() => {
    if (lockAcquiredUntil && lockAcquiredUntil !== previousLockUntil.current) {
      previousLockUntil.current = lockAcquiredUntil;
      const delay = lockAcquiredUntil - Date.now() - LOCK_EXTENSION_CALL_BUFFER_IN_MS;
      const timeoutId = setTimeout(() => {
        dispatch(
          updateSurveyLockAction.BEGIN({
            onSuccess: () => {
              shouldFetchLockDetails.current = true;
            },
            body: {
              surveyId,
              actionType: SURVEY_LOCK_ACTIONS.Update,
              sessionId: updateSurveyLockData.sessionId,
            },
          }),
        );
      }, delay);
      return () => {
        clearTimeout(timeoutId);
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockAcquiredUntil]);
}
