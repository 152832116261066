import React, { useState } from 'react';
import { get, isEmpty, map } from 'lodash';
import {
  Header,
  Container,
  Checkbox,
  Alert,
  Button,
  Input,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { errorMessages } from '../../../constants/steps-config';
import { getAttestationContent, getAttestationSlice } from '../utils/attestations_list';
import TEMPLATE_IDS from '../../../../../common/constants/templateIds';
import { getSubmitAttestationConfig } from '../../../../../common/constants/surveyType';
import { isReadOnly } from '../../../utils/survey_page_utils';

const Submit = ({ state, setState, templateId, surveyType, surveyDetails }) => {
  const attestationConfig = getSubmitAttestationConfig(surveyType);
  const [isVisible, setIsVisible] = useState({
    textArea: get(state, 'Submit.data', []).includes(
      getAttestationContent(attestationConfig.attestationEmployeeListId, surveyType),
    ),
    info: get(state, 'Submit.data', []).includes(
      getAttestationContent(attestationConfig.attestationInfoId, surveyType),
    ),
  });

  const textBoxContentHandler = ({ detail }) => {
    setState({
      key: 'Submit',
      value: {
        ...state.Submit,
        textbox: detail.value,
      },
    });
  };

  function onChangeHandler(event, content) {
    const { detail } = event;
    const value = detail.checked;
    let textBox = get(state, 'Submit.textbox');
    switch (content) {
      case getAttestationContent(attestationConfig.attestationEmployeeListId, surveyType): {
        setIsVisible({ ...isVisible, textArea: value });
        if (!value) textBox = '';
        break;
      }
      case getAttestationContent(attestationConfig.attestationInfoId, surveyType): {
        setIsVisible({ ...isVisible, info: value });
        break;
      }
      default:
    }
    if (!value) {
      const filteredArray = state.Submit.data.filter(attestation => attestation !== content);
      setState({
        key: 'Submit',
        value: {
          ...state.Submit,
          data: [...filteredArray],
          textbox: textBox,
        },
      });
    } else {
      setState({
        key: 'Submit',
        value: {
          ...state.Submit,
          data: [...state.Submit.data, content],
        },
      });
    }
  }

  const { data } = state.Submit;

  const showErrors = Object.keys(state).filter(page => state[page].error === true);

  const goToPage = page => {
    const pages = Object.keys(state);
    const step = pages.findIndex(p => p === page);
    setState({
      key: 'activeStep',
      value: step,
    });
  };

  const getCheckBoxes = list => {
    return map(list, attestation => {
      const { content } = attestation;
      return (
        <div style={{ marginBottom: '5px' }}>
          <Checkbox
            onChange={event => onChangeHandler(event, content)}
            checked={data.includes(content)}
            disabled={isReadOnly(surveyDetails)}
          >
            {content}
          </Checkbox>
        </div>
      );
    });
  };

  const getErrorMessages = errorPages => {
    if (
      errorPages === 'Submit' &&
      get(state, 'Submit.data', []).includes(
        getAttestationContent(attestationConfig.attestationEmployeeListId, surveyType),
      )
    )
      return get(errorMessages, [surveyType, 'EntityNames']);
    return get(errorMessages, [surveyType, errorPages]);
  };

  const submitAPIError = get(state, 'SubmitAPI.error', null);
  return (
    <div>
      {submitAPIError && (
        <Alert visible="true" dismissAriaLabel="Close alert" type="error">
          {submitAPIError}
        </Alert>
      )}
      {showErrors.map(errorPages => {
        return (
          get(errorMessages, [surveyType, errorPages]) && (
            <Alert
              visible="true"
              dismissAriaLabel="Close alert"
              type="error"
              action={<Button onClick={() => goToPage(errorPages)}>Resolve</Button>}
              header={getErrorMessages(errorPages)}
            />
          )
        );
      })}
      <Container
        className="allocation-container"
        header={<Header variant="h2">Attestation</Header>}
      >
        <SpaceBetween size="s">
          <div>Select all the methodology(s) you used to complete the survey.</div>
          <div>
            {getCheckBoxes(
              getAttestationSlice(surveyType, 0, attestationConfig.defaultAttestationEndIndex),
            )}
            {isVisible.info && TEMPLATE_IDS.includes(templateId) ? (
              <Alert type="info">
                Make sure to provide the documents you reviewed in Step 3 Project documents.
              </Alert>
            ) : (
              <div />
            )}
            {getCheckBoxes(
              getAttestationSlice(
                surveyType,
                attestationConfig.defaultAttestationEndIndex,
                attestationConfig.consultAttestationEndIndex,
              ),
            )}
            {isVisible.textArea ? (
              <Input
                onChange={textBoxContentHandler}
                value={get(state, 'Submit.textbox', '')}
                placeholder="Enter comma separated employee names"
                invalid={isEmpty(get(state, 'Submit.textbox', ''))}
                disabled={isReadOnly(surveyDetails)}
              />
            ) : (
              <div />
            )}
          </div>
        </SpaceBetween>
      </Container>
    </div>
  );
};

Submit.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  templateId: PropTypes.string.isRequired,
  surveyType: PropTypes.string.isRequired,
  surveyDetails: PropTypes.object.isRequired,
};

export default Submit;
