import React, { useEffect } from 'react';
import { Alert, Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { forEach, get, map, isEmpty, floor, filter } from 'lodash';
import PropTypes from 'prop-types';

import { round, sum } from 'lodash/math';
import { values } from 'lodash/object';
import { keys } from 'lodash/fp/_util';
import AllocationGrid from '../../AllocationGrid/AllocationGrid';
import { errorMessages, validationInitialState } from '../../../constants/steps-config';
import { getEntityData, getSurveyEntity } from '../../../../../common/constants/surveyType';

const ProjectAllocation = ({
  state,
  setState,
  pageContents,
  validationState,
  setValidationState,
  onNavigate,
  surveyType,
  surveyDetails,
}) => {
  const entityData = getEntityData(pageContents, surveyType);
  const projectRemoved = get(validationState, 'projectAllocationState.projectRemoved', false);
  const selectedProject = get(state, 'Project.data.selectedProjects', {});

  useEffect(() => {
    const Projects = map(get(state, 'Project.data.selectedProjects', []), ob => ob.id);
    const oldState = state.ProjectAllocation.data;

    const newState = {};
    forEach(keys(oldState), ob => {
      const x = {};
      forEach(Projects, key => {
        if (oldState[ob][key] && oldState[ob][key] !== '0') x[key] = oldState[ob][key];
      });
      x.total = sum(map(values(x), val => round(parseFloat(val), 1)))
        .toFixed(1)
        .toString();
      newState[ob] = x;
    });
    setState({
      key: 'ProjectAllocation',
      value: {
        ...state.ProjectAllocation,
        data: newState,
        errorMessage: Projects.length === 0 ? get(errorMessages, [surveyType, 'Project']) : null,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const projects = get(state, 'Project.data.selectedProjects', []);

  const validateProjectAllocation = project => {
    const entityList = entityData;
    if (isEmpty(project) || Object.keys(project).length !== entityList.length) return false;
    let validation = true;
    Object.keys(project).forEach(employee => {
      if (Number(project[employee].total) !== 100) {
        validation = false;
      }
    });
    return validation;
  };

  const onAllocationChange = (val, pid, eid) => {
    if (Number.isNaN(Number(val))) return;
    let value = val || '0';
    if (Number(value) < 0) return;
    value = round(Number(value), 1);
    const prevVal =
      (state.ProjectAllocation.data[eid] && state.ProjectAllocation.data[eid][pid]) || '0';
    const prevTotal =
      (state.ProjectAllocation.data[eid] && state.ProjectAllocation.data[eid].total) || '0';
    let newTotal = floor(parseFloat(prevTotal), 1) - round(parseFloat(prevVal), 1) + value;
    newTotal = newTotal.toFixed(1);

    const newData = {
      ...state.ProjectAllocation.data,
      [eid]: {
        ...state.ProjectAllocation.data[eid],
        [pid]: val,
        total: newTotal,
      },
    };

    setState({
      key: 'ProjectAllocation',
      value: {
        ...state.ProjectAllocation,
        data: newData,
        error: !validateProjectAllocation(newData),
      },
    });
  };

  const uploadedData = importedData => {
    if (importedData.status === 'error') {
      setState({
        key: 'ProjectAllocation',
        value: {
          ...state.ProjectAllocation,
          errorMessage: importedData.errorMessage,
        },
      });
    } else {
      setState({
        key: 'ProjectAllocation',
        value: {
          ...state.ProjectAllocation,
          data: importedData,
          error: !validateProjectAllocation(importedData),
          errorMessage: null,
        },
      });
    }
  };

  const updateActiveIndex = () => {
    setState({
      key: 'activeStep',
      value: 3,
    });
  };
  const closeNoAllocationModal = () => {
    setValidationState(validationInitialState);
  };
  useEffect(() => {
    if (projectRemoved) {
      setValidationState(validationInitialState);
      const detail = {
        requestedStepIndex: state.activeStep + 1,
      };
      onNavigate({ detail });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, projectRemoved]);
  const removeProjectFromSelectedList = () => {
    const currentSelectedProject = get(state, 'Project.data.selectedProjects', {});
    const removeProjectId = validationState.projectAllocationState.projectId;
    const currentSelection = filter(currentSelectedProject, project => {
      return project.id !== removeProjectId;
    });
    setState({
      key: 'Project',
      value: {
        ...state.Project,
        data: {
          ...get(state, 'Project.data', {}),
          selectedProjects: currentSelection,
        },
      },
    });
    setValidationState(prevState => {
      return {
        ...prevState,
        projectAllocationState: {
          ...prevState.projectAllocationState,
          projectRemoved: true,
        },
      };
    });
  };

  const { error, errorMessage } = state.ProjectAllocation;

  return (
    <div>
      {error && (
        <Alert visible="true" dismissAriaLabel="Close alert" type="error">
          You must allocate a total of 100% to each {getSurveyEntity(surveyType)}
        </Alert>
      )}

      {errorMessage && (
        <Alert visible="true" dismissAriaLabel="Close alert" type="error">
          {errorMessage}
        </Alert>
      )}

      <AllocationGrid
        titleEnitity="projects"
        title="Project Matrix"
        colsEntity={entityData}
        rowsEntity={projects}
        gridValues={get(state, 'ProjectAllocation.data', {})}
        onAllocationChange={onAllocationChange}
        uploadedData={uploadedData}
        selectEntity={updateActiveIndex}
        isDownloadUploadButtonDisabled={projects.length === 0}
        allocationType="Project"
        surveyType={surveyType}
        surveyDetails={surveyDetails}
      />
      <Modal
        onDismiss={closeNoAllocationModal}
        visible={validationState.projectAllocationState.modalVisible}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="normal" onClick={closeNoAllocationModal}>
                Edit allocation
              </Button>
              <Button variant="primary" onClick={removeProjectFromSelectedList}>
                Proceed
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Project has no time allocation"
      >
        <div>
          You didn&apos;t allocate any of the employee&apos;s time to project{' '}
          <b>{validationState.projectAllocationState.projectName}</b>. If you choose to proceed,
          this project will be removed from your project list.
        </div>
      </Modal>
    </div>
  );
};
ProjectAllocation.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  pageContents: PropTypes.object.isRequired,
  surveyType: PropTypes.string.isRequired,
  validationState: PropTypes.object.isRequired,
  setValidationState: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  surveyDetails: PropTypes.object.isRequired,
};
export default ProjectAllocation;
