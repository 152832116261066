import Employee from '../components/Employee/components/Employee';
import Vendor from '../components/Vendor/components/Vendor';
import Projects from '../components/Projects/components/Projects';
import Activity from '../components/Activity/component/Activity';
import ProjectAllocation from '../components/ProjectAllocation/components/ProjectAllocation';
import ActivityAllocation from '../components/ActivityAllocation/components/ActivityAllocation';
import Submit from '../components/Submit/components/Submit';
import ProjectDocuments from '../components/ProjectDocuments/components/ProjectDocuments';
import { PROJECT_TEMPLATE_IDS } from '../../../common/constants/templateIds';
import { getSurveySteps } from '../../../common/constants/surveyType';

// Order of default Steps in this file and validationSteps in
// survey_page/components/TWizard/TWizard.jsx file should be same.
const defaultSteps = [
  {
    title: 'Activity',
    stateKey: 'Activity',
    StepContent: Activity,
    validationOnNextButton: [],
  },
  {
    title: 'Activity allocation',
    stateKey: 'ActivityAllocation',
    StepContent: ActivityAllocation,
    // use "isActivityHasNoAllocation" function to validate if any activity has zero allocation.
    validationOnNextButton: [],
  },
  {
    title: 'Project',
    stateKey: 'Project',
    StepContent: Projects,
    validationOnNextButton: [],
  },
  {
    title: 'Project allocation',
    stateKey: 'ProjectAllocation',
    StepContent: ProjectAllocation,
    // use "isProjectHasNoAllocation" function to validate if any project has zero allocation.
    validationOnNextButton: [],
  },
  {
    title: 'Submit',
    stateKey: 'Submit',
    StepContent: Submit,
    validationOnNextButton: [],
  },
];

const employeeStep = {
  title: 'Employee',
  stateKey: 'Employee',
  StepContent: Employee,
  validationOnNextButton: [],
};

const vendorStep = {
  title: 'Supplier',
  stateKey: 'Vendor',
  StepContent: Vendor,
  validationOnNextButton: [],
};

const isProjectDocumentsPresent = () => {
  return defaultSteps.find(step => step.stateKey === 'ProjectDocuments');
};

const steps = (templateId, surveyType) => {
  const surveySteps = getSurveySteps(surveyType, vendorStep, employeeStep, defaultSteps);
  if (!PROJECT_TEMPLATE_IDS.includes(templateId) && !isProjectDocumentsPresent()) {
    surveySteps.splice(5, 0, {
      title: 'Project documents',
      stateKey: 'ProjectDocuments',
      StepContent: ProjectDocuments,
      isOptional: true,
    });
  }
  return surveySteps;
};

export default steps;
