import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import NotFoundPage from './app/not_found/NotFoundPage';
import {
  ROUTE_PATH,
  getRoutePathWithStudyPeriod,
  getRoutePathWithStudyPeriodPlaceholder,
} from './common/constants/route_paths';
import LandingPage from './app/landing/sme/LandingPage';
import SurveyPage from './app/survey_page/SurveyPage';
import RnDLandingPage from './app/landing/rnd/RnDLandingPage';
import { STUDY_PERIOD } from './common/constants/study_period';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        path={ROUTE_PATH.HOME_SCREEN}
        to={getRoutePathWithStudyPeriod(ROUTE_PATH.DASHBOARD, STUDY_PERIOD[2023])}
      />
      ;
      <Route
        path={getRoutePathWithStudyPeriodPlaceholder(ROUTE_PATH.DASHBOARD)}
        component={LandingPage}
      />
      <Route
        path={getRoutePathWithStudyPeriodPlaceholder(ROUTE_PATH.RND_DASHBOARD)}
        component={RnDLandingPage}
      />
      <Route
        path={getRoutePathWithStudyPeriodPlaceholder(ROUTE_PATH.SURVEY)}
        component={SurveyPage}
      />
      <Route
        path={getRoutePathWithStudyPeriodPlaceholder(ROUTE_PATH.NOT_FOUND_SCREEN)}
        component={NotFoundPage}
      />
      <Redirect to={getRoutePathWithStudyPeriod(ROUTE_PATH.NOT_FOUND_SCREEN, STUDY_PERIOD[2023])} />
    </Switch>
  );
};

export default Routes;
