import React from 'react';
import Flashbar from '@amzn/awsui-components-react/polaris/flashbar';
import { v4 as uuidv4 } from 'uuid';
import { get, isEmpty, isNull } from 'lodash';
import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from './constants';
import { REASSIGN_SURVEY_TYPES } from '../../../../app/survey_page/components/Employee/redux/reassignSurvey';
import { getSerializedData } from '../../../../app/survey_page/utils/redux_payload_utils';
import { SAVE_SURVEY_ACTION_TYPES } from '../../../../app/survey_page/redux/saveSurvey';
import { UPDATE_SURVEY_LOCK_ACTION_TYPES } from '../../../../app/landing/common/redux/UpdateSurveyLock';

const getReassignmentMessage = (statusType, payload) => {
  const body = getSerializedData(get(payload, 'body', '{}'));
  switch (statusType) {
    case NOTIFICATION_TYPE_SUCCESS:
      return get(body, 'reassignmentMessage', 'Reassignment successful');
    case NOTIFICATION_TYPE_ERROR: {
      const reassignmentMessage = get(body, 'reassignmentMessage');
      if (!isNull(reassignmentMessage) && !isEmpty(reassignmentMessage)) return reassignmentMessage;
      return get(payload, 'statusMessage', 'An error occurred while reassigning');
    }
    default:
      return null;
  }
};

export const getNotificationFlash = (notification, clearPageNotificationHandler) => {
  if (!notification || !clearPageNotificationHandler) {
    return <div />;
  }

  const { type, content } = notification;
  if (!type || !content) {
    return <div />;
  }

  const flashItems = [
    {
      type,
      content,
      dismissible: true,
      onDismiss: clearPageNotificationHandler,
    },
  ];

  return <Flashbar key={Math.random()} items={flashItems} />;
};

export const getBanner = message => {
  if (!message) {
    return <div />;
  }
  return <h1 className="awsui-util-t-c">{message}</h1>;
};

export const notificationActionHandler = ({ type, payload }) => {
  const status = get(payload, 'status', null);
  switch (type) {
    case REASSIGN_SURVEY_TYPES.SUCCESS: {
      const body = getSerializedData(get(payload, 'body', '{}'));
      const statusType =
        status === 200 && get(body, 'reassignmentOutcome', false)
          ? NOTIFICATION_TYPE_SUCCESS
          : NOTIFICATION_TYPE_ERROR;
      const content = getReassignmentMessage(statusType, payload);
      return {
        type: statusType,
        content,
        id: uuidv4(),
      };
    }
    case SAVE_SURVEY_ACTION_TYPES.SUCCESS: {
      if (status !== 200) {
        return {
          type: NOTIFICATION_TYPE_ERROR,
          content: get(payload, 'statusMessage', 'An error occurred while saving survey.'),
          id: uuidv4(),
        };
      }
      return null;
    }
    case UPDATE_SURVEY_LOCK_ACTION_TYPES.SUCCESS: {
      if (status !== 200) {
        return {
          type: NOTIFICATION_TYPE_ERROR,
          content: get(
            payload,
            'statusMessage',
            'An error occurred while acquiring the lock on survey.',
          ),
          id: uuidv4(),
        };
      }
      return null;
    }
    default:
      return null;
  }
};
