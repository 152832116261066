import React, { useEffect } from 'react';
import { Alert, Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { forEach, get, map, isEmpty, floor, filter } from 'lodash';
import PropTypes from 'prop-types';
import { keys } from 'lodash/fp/_util';
import { round, sum } from 'lodash/math';
import { values } from 'lodash/object';
import AllocationGrid from '../../AllocationGrid/AllocationGrid';
import { getEntityData, getSurveyEntity } from '../../../../../common/constants/surveyType';
import { validationInitialState } from '../../../constants/steps-config';

const ActivityAllocation = ({
  state,
  setState,
  pageContents,
  validationState,
  setValidationState,
  onNavigate,
  surveyType,
  surveyDetails,
}) => {
  const entityData = getEntityData(pageContents, surveyType);
  const DefaultActivities = get(state, 'Activity.data.defaultItems', []);
  const selectedActivities = get(state, 'Activity.data.selectedActivities', []);
  const activityRemoved = get(validationState, 'activityAllocationState.activityRemoved', false);

  useEffect(() => {
    const oldState = state.ActivityAllocation.data;
    const Activities = map([...DefaultActivities, ...selectedActivities], ob => ob.id);
    const newState = {};

    forEach(keys(oldState), col => {
      const x = {};
      forEach(Activities, row => {
        if (oldState[col][row] && oldState[col][row] !== '0') {
          x[row] = oldState[col][row];
        }
      });
      x.total = sum(map(values(x), val => round(parseFloat(val), 1)))
        .toFixed(1)
        .toString();
      newState[col] = x;
    });
    setState({
      key: 'ActivityAllocation',
      value: {
        ...state.ActivityAllocation,
        data: newState,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedActivities)]);

  const activities = [...DefaultActivities, ...selectedActivities];

  const validateActivityAllocation = activity => {
    const entityList = entityData;
    if (isEmpty(activity) || Object.keys(activity).length !== entityList.length) return false;
    let validation = true;
    Object.keys(activity).forEach(entity => {
      if (Number(activity[entity].total) !== 100) {
        validation = false;
      }
    });

    return validation;
  };

  const onAllocationChange = (val, rowId, colId) => {
    if (Number.isNaN(Number(val))) return;
    let value = val || '0';
    if (Number(value) < 0) return;
    value = round(Number(value), 1);
    const prevVal =
      (state.ActivityAllocation.data[colId] && state.ActivityAllocation.data[colId][rowId]) || '0';
    const prevTotal =
      (state.ActivityAllocation.data[colId] && state.ActivityAllocation.data[colId].total) || '0';
    let newTotal = floor(parseFloat(prevTotal), 1) - round(parseFloat(prevVal), 1) + value;
    newTotal = newTotal.toFixed(1);
    const newData = {
      ...state.ActivityAllocation.data,
      [colId]: {
        ...state.ActivityAllocation.data[colId],
        [rowId]: val,
        total: newTotal,
      },
    };

    setState({
      key: 'ActivityAllocation',
      value: {
        ...state.ActivityAllocation,
        data: newData,
        error: !validateActivityAllocation(newData),
      },
    });
  };

  const uploadedData = importedData => {
    if (importedData.status === 'error') {
      setState({
        key: 'ActivityAllocation',
        value: {
          ...state.ActivityAllocation,
          errorMessage: importedData.errorMessage,
        },
      });
    } else {
      setState({
        key: 'ActivityAllocation',
        value: {
          ...state.ActivityAllocation,
          data: importedData,
          error: !validateActivityAllocation(importedData),
          errorMessage: null,
        },
      });
    }
  };

  const updateActiveIndex = () => {
    setState({
      key: 'activeStep',
      value: 1,
    });
  };
  const closeNoAllocationModal = () => {
    setValidationState(validationInitialState);
  };
  useEffect(() => {
    if (activityRemoved) {
      setValidationState(validationInitialState);
      const detail = {
        requestedStepIndex: state.activeStep + 1,
      };
      onNavigate({ detail });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DefaultActivities, selectedActivities, activityRemoved]);
  const removeActivityFromSelectedList = () => {
    const defaultActivities = get(state, 'Activity.data.defaultItems', {});
    const selectedActivity = get(state, 'Activity.data.selectedActivities', {});
    const removeActivityId = validationState.activityAllocationState.activityId;
    const currentDefaultActivity = filter(defaultActivities, activity => {
      return activity.id !== removeActivityId;
    });
    const currentSelectedActivity = filter(selectedActivity, activity => {
      return activity.id !== removeActivityId;
    });
    setState({
      key: 'Activity',
      value: {
        ...state.Activity,
        data: {
          ...get(state, 'Activity.data', {}),
          defaultItems: currentDefaultActivity,
          selectedActivities: currentSelectedActivity,
        },
      },
    });
    setValidationState(prevState => {
      return {
        ...prevState,
        activityAllocationState: {
          ...prevState.activityAllocationState,
          activityRemoved: true,
        },
      };
    });
  };

  const { error, errorMessage } = state.ActivityAllocation;

  return (
    <div>
      {error && (
        <Alert visible="true" dismissAriaLabel="Close alert" type="error">
          You must allocate a total of 100% to each {getSurveyEntity(surveyType)}
        </Alert>
      )}

      {errorMessage && (
        <Alert visible="true" dismissAriaLabel="Close alert" type="error">
          {errorMessage}
        </Alert>
      )}

      <AllocationGrid
        titleEnitity="activities"
        title="Activity Matrix"
        colsEntity={entityData}
        rowsEntity={activities}
        gridValues={get(state, 'ActivityAllocation.data', {})}
        onAllocationChange={onAllocationChange}
        uploadedData={uploadedData}
        selectEntity={updateActiveIndex}
        allocationType="Activity"
        surveyType={surveyType}
        surveyDetails={surveyDetails}
      />
      <Modal
        onDismiss={closeNoAllocationModal}
        visible={validationState.activityAllocationState.modalVisible}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="normal" onClick={closeNoAllocationModal}>
                Edit allocation
              </Button>
              <Button variant="primary" onClick={removeActivityFromSelectedList}>
                Proceed
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Activity has no time allocation"
      >
        <div>
          You didn&apos;t allocate any of the employee&apos;s time to activity{' '}
          <b>{validationState.activityAllocationState.activityName}</b>. If you choose to proceed,
          this activity will be removed from your activity list.
        </div>
      </Modal>
    </div>
  );
};

ActivityAllocation.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  pageContents: PropTypes.object.isRequired,
  surveyType: PropTypes.string.isRequired,
  validationState: PropTypes.object.isRequired,
  setValidationState: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  surveyDetails: PropTypes.object.isRequired,
};
export default ActivityAllocation;
