import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Cards,
  Header,
  SpaceBetween,
  Modal,
  FormField,
  Input,
  Link,
} from '@amzn/awsui-components-react';
import { get, map, size, isEmpty, filter } from 'lodash';
import PropTypes from 'prop-types';
import { difference } from 'lodash/array';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { CREATE_PROJECT, VALARI_API } from '../../../../../common/config/api_endpoints';
import '../style.css';
import getDocById from '../../../../../common/components/doc_link/utils/getDocById';
import FAQ from '../../../../../common/constants/documentIds';
import DocLink from '../../../../../common/components/doc_link/utils/DocLink';
import UncheckProjectModal from './UncheckProjectModal';
import {
  projectDataInitialState,
  deleteProjectModalInitialState,
  modalTypes,
} from '../constants/projects_config';
import {
  getSortedProjects,
  isProjectAllocationPresent,
  mappedProjectToSelectedProject,
  sortFilteredProjects,
} from '../utils/utils';
import addContextToPayload from '../../../../../common/utils/api_util';
import { getStudyPeriod } from '../../../../../common/constants/study_period';
import TextFiltering from '../../../../../common/components/table/components/TextFiltering';
import { setPageNotification } from '../../../../../common/components/with_page/redux/reducer';
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_INFO,
  NOTIFICATION_TYPE_SUCCESS,
} from '../../../../../common/components/with_page/notifications/constants';
import { isReadOnly } from '../../../utils/survey_page_utils';

const Projects = ({
  state,
  setState,
  pageContents,
  setPageElements,
  surveyDetails,
  studyPeriod,
  pushNotification,
}) => {
  const [projectData, setProjectData] = useState(projectDataInitialState);
  const [deleteProjectModal, setDeleteProjectModal] = useState(deleteProjectModalInitialState);
  const selectedProjects = get(state, 'Project.data.selectedProjects', []);
  const listOfProjects = map(
    get(pageContents, 'Project.projectList', []),
    mappedProjectToSelectedProject,
  );
  const [visibleItems, setVisibleItems] = useState(
    getSortedProjects(listOfProjects, selectedProjects),
  );

  useEffect(() => {
    setVisibleItems(getSortedProjects(listOfProjects, selectedProjects));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, pageContents]);
  const setSortedVisibleItems = items => {
    setVisibleItems(sortFilteredProjects(items, selectedProjects));
  };
  const setSelectedProjects = currentSelection => {
    setState({
      key: 'Project',
      value: {
        ...state.Project,
        data: {
          ...get(state, 'Project.data', {}),
          selectedProjects: currentSelection,
        },
      },
    });
    setDeleteProjectModal(deleteProjectModalInitialState);
  };

  const confirmDelete = ({ projectIdVal }) => {
    const projectId = isEmpty(projectIdVal)
      ? get(deleteProjectModal, 'selectedProject.projectId', '')
      : projectIdVal;
    const request = {
      body: {
        operation: 'Delete',
        projectId,
      },
    };
    addContextToPayload(VALARI_API, CREATE_PROJECT, studyPeriod, request).then(response => {
      if (response.status === 200) {
        const updatedProjectList = pageContents.Project.projectList.filter(project => {
          return project.projectId !== projectId;
        });

        const updatedSelectedList = filter(
          get(state, 'Project.data.selectedProjects', []),
          ({ id }) => id !== projectId,
        );

        setPageElements({
          ...pageContents,
          Project: {
            ...pageContents.Project,
            projectList: [...updatedProjectList],
          },
        });
        setState({
          key: 'Project',
          value: {
            ...state.Project,
            data: {
              ...state.Project.data,
              selectedProjects: [...updatedSelectedList],
            },
          },
        });
        setVisibleItems(
          getSortedProjects(
            map(updatedProjectList, mappedProjectToSelectedProject),
            updatedSelectedList,
          ),
        );
        if (!(updatedSelectedList.length > 0)) {
          pushNotification({
            type: NOTIFICATION_TYPE_ERROR,
            content: 'You must select at least one project.',
            id: uuidv4(),
          });
        }
        pushNotification({
          type: NOTIFICATION_TYPE_INFO,
          content: (
            <>
              Project <b>{projectData.projectTitle}</b> has been deleted.
            </>
          ),
          id: uuidv4(),
        });
        setProjectData(prevState => {
          return {
            ...prevState,
            ...projectDataInitialState,
          };
        });
      }
    });
    setDeleteProjectModal(deleteProjectModalInitialState);
  };

  const onConfirmHandler = () => {
    switch (deleteProjectModal.type) {
      case modalTypes.REMOVE: {
        confirmDelete({ projectIdVal: deleteProjectModal.selectedProject.projectId });
        break;
      }
      case modalTypes.UNSELECT: {
        setVisibleItems(getSortedProjects(listOfProjects, deleteProjectModal.newProjects));
        setSelectedProjects(deleteProjectModal.newProjects);
        break;
      }
      default:
    }
  };
  const projectSelectionHandler = ({ detail }) => {
    const currentSelection = get(detail, 'selectedItems');
    const prevSelection = get(state, 'Project.data.selectedProjects', []);
    if (size(currentSelection) < size(prevSelection)) {
      const unSelectedProject = difference(prevSelection, currentSelection)[0];
      if (isProjectAllocationPresent(state, unSelectedProject.id)) {
        setDeleteProjectModal({
          visible: true,
          selectedProject: unSelectedProject,
          newProjects: currentSelection,
          type: modalTypes.UNSELECT,
        });
      } else {
        setVisibleItems(getSortedProjects(listOfProjects, currentSelection));
        setSelectedProjects(currentSelection);
      }
    } else {
      setVisibleItems(getSortedProjects(listOfProjects, currentSelection));
      setSelectedProjects(currentSelection);
    }
  };

  const openModal = () => {
    setProjectData(prevState => {
      return {
        ...prevState,
        addProjectModalState: true,
        projectDeleteModalState: false,
        projectDetailsModalState: false,
      };
    });
  };

  const closeModal = () => {
    setProjectData(projectDataInitialState);
  };

  const isProjectSelected = projectId => {
    return selectedProjects.find(project => project.id === projectId);
  };

  const addProject = () => {
    if (projectData.projectId) {
      const request = {
        body: {
          operation: 'Update',
          projectDescription: projectData.projectDesc,
          projectName: projectData.projectTitle,
          projectId: projectData.projectId,
        },
      };
      addContextToPayload(VALARI_API, CREATE_PROJECT, studyPeriod, request).then(response => {
        if (response.status === 200) {
          const updatedProjectList = pageContents.Project.projectList.map(project => {
            return project.projectId === projectData.projectId
              ? JSON.parse(get(response, 'body.data', {}))
              : project;
          });
          setPageElements({
            ...pageContents,
            Project: {
              ...pageContents.Project,
              projectList: [...updatedProjectList],
            },
          });
          setProjectData(prevState => {
            return {
              ...prevState,
              ...projectDataInitialState,
            };
          });
          pushNotification({
            type: NOTIFICATION_TYPE_SUCCESS,
            content: 'Project updated successfully.',
            id: uuidv4(),
          });
          let updatedSelectedProject = selectedProjects;
          if (isProjectSelected(projectData.projectId)) {
            updatedSelectedProject = selectedProjects.map(project => {
              return project.id === projectData.projectId
                ? mappedProjectToSelectedProject(JSON.parse(get(response, 'body.data', {})))
                : project;
            });
            setState({
              key: 'Project',
              value: {
                ...state.Project,
                data: { selectedProjects: [...updatedSelectedProject] },
              },
            });
          }
          setVisibleItems(
            getSortedProjects(
              map(updatedProjectList, mappedProjectToSelectedProject),
              updatedSelectedProject,
            ),
          );
        }
      });
    } else {
      const request = {
        body: {
          operation: 'Create',
          projectDescription: projectData.projectDesc,
          projectName: projectData.projectTitle,
          smeEmployeeId: surveyDetails.userId,
          surveyCostCenter: surveyDetails.surveyCostCenter,
          surveyAmazonOFACostCenterNumber: surveyDetails.surveyAmazonOFACostCenterNumber,
          surveyId: surveyDetails.surveyId,
          surveyCompanyOfaCode: surveyDetails.surveyCompanyOfaCode,
          businessUnit: surveyDetails.businessUnit,
        },
      };
      addContextToPayload(VALARI_API, CREATE_PROJECT, studyPeriod, request).then(response => {
        if (response.status === 200) {
          const updatedProjectList = [
            JSON.parse(get(response, 'body.data', {})),
            ...pageContents.Project.projectList,
          ];
          const updatedSelectedProject = [
            mappedProjectToSelectedProject(JSON.parse(get(response, 'body.data', {}))),
            ...get(state, 'Project.data.selectedProjects', []),
          ];
          setPageElements({
            ...pageContents,
            Project: {
              ...pageContents.Project,
              projectList: updatedProjectList,
            },
          });
          setState({
            key: 'Project',
            value: {
              ...state.Project,
              data: {
                ...get(state, 'Project.data', {}),
                selectedProjects: updatedSelectedProject,
              },
            },
          });
          setProjectData(prevState => {
            return {
              ...prevState,
              ...projectDataInitialState,
            };
          });
          setVisibleItems(
            getSortedProjects(
              map(updatedProjectList, mappedProjectToSelectedProject),
              updatedSelectedProject,
            ),
          );
          pushNotification({
            type: NOTIFICATION_TYPE_SUCCESS,
            content: 'Project created successfully.',
            id: uuidv4(),
          });
        }
      });
    }
    closeModal();
  };

  const deleteProject = () => {
    let projectName = '';
    const updatedSelectedList = get(state, 'Project.data.selectedProjects', []).filter(project => {
      if (project.id === projectData.projectId) {
        projectName = project.name;
      }
      return project.id !== projectData.projectId;
    });
    if (isProjectAllocationPresent(state, projectData.projectId) && !isEmpty(projectName)) {
      setDeleteProjectModal({
        visible: true,
        selectedProject: {
          projectName,
          projectId: projectData.projectId,
        },
        newProjects: updatedSelectedList,
        type: modalTypes.REMOVE,
      });
    } else {
      confirmDelete({ projectIdVal: projectData.projectId });
    }
  };

  const setTitle = title => {
    setProjectData(prevState => {
      return {
        ...prevState,
        projectTitle: title.substring(0, Math.min(title.length, 150)),
      };
    });
  };

  const setDescription = projectDesc => {
    setProjectData(prevState => {
      return {
        ...prevState,
        projectDesc,
      };
    });
  };

  const openProjectDetailsModal = (event, project) => {
    setProjectData(prevState => {
      return {
        ...prevState,
        addProjectModalState: false,
        projectDeleteModalState: false,
        projectDetailsModalState: true,
        projectId: project.id,
        projectTitle: project.name,
        projectDesc: project.description,
        isUserCreated: project.isUserCreated,
      };
    });
  };

  const openProjectDeleteModal = () => {
    setProjectData(prevState => {
      return {
        ...prevState,
        addProjectModalState: false,
        projectDeleteModalState: true,
        projectDetailsModalState: false,
      };
    });
  };
  const getProjectDetailsFooter = () => {
    if (projectData.isUserCreated === 'true') {
      return (
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={openProjectDeleteModal}
              disabled={isReadOnly(surveyDetails)}
            >
              Delete project
            </Button>
            <Button variant="normal" onClick={openModal} disabled={isReadOnly(surveyDetails)}>
              Edit project
            </Button>
          </SpaceBetween>
        </Box>
      );
    }
    return '';
  };
  return (
    <SpaceBetween size="m" className="project-step">
      <Modal
        onDismiss={closeModal}
        visible={projectData.addProjectModalState}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={!projectData.projectTitle}
                onClick={() => {
                  addProject();
                }}
              >
                Save
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={projectData.projectId ? 'Project details' : 'Add Project'}
      >
        <FormField label="Project Name">
          <Input
            type="text"
            value={projectData.projectTitle}
            onChange={({ detail }) => setTitle(detail.value)}
          />
        </FormField>
        <br />
        <FormField label="Project Description - optional">
          <Input
            type="text"
            value={projectData.projectDesc}
            onChange={({ detail }) => setDescription(detail.value)}
          />
        </FormField>
      </Modal>

      <Modal
        onDismiss={closeModal}
        visible={projectData.projectDetailsModalState}
        closeAriaLabel="Close modal"
        size="medium"
        footer={getProjectDetailsFooter()}
        header="Project details"
      >
        <FormField label="Project name">
          <p>{projectData.projectTitle}</p>
        </FormField>
        <br />
        <FormField label="Project description">
          <p>{projectData.projectDesc}</p>
        </FormField>
      </Modal>

      <Modal
        onDismiss={closeModal}
        visible={projectData.projectDeleteModalState}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={closeModal}>
                Cancel
              </Button>
              <Button variant="normal" onClick={deleteProject}>
                Delete project
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Delete project"
      >
        <div>
          Are you sure you want to delete <b>{projectData.projectTitle}</b>?
        </div>
      </Modal>
      <Cards
        header={
          <Header
            counter={`(${size(get(state, 'Project.data.selectedProjects', []))}/${size(
              listOfProjects,
            )})`}
            info={
              <DocLink
                pdf={getDocById(FAQ)}
                text="Why am I seeing a project list?"
                variant="info"
              />
            }
            variant="h2"
            actions={
              <Button onClick={() => openModal()} disabled={isReadOnly(surveyDetails)}>
                Add project
              </Button>
            }
          >
            Select projects
          </Header>
        }
        items={visibleItems}
        trackBy="id"
        selectionType="multi"
        loadingText="Loading projects"
        selectedItems={get(state, 'Project.data.selectedProjects')}
        cardsPerRow={[{ cards: 2 }]}
        stickyHeader
        isItemDisabled={() => isReadOnly(surveyDetails)}
        filter={
          <TextFiltering
            setVisibleItems={setSortedVisibleItems}
            filterOn={['name']}
            data={listOfProjects}
            filteringPlaceholder="Search project"
          />
        }
        cardDefinition={{
          header: e => (
            <div className="project-container">
              <div className="project-title-bar">
                <Link
                  onFollow={event => {
                    openProjectDetailsModal(event, e);
                  }}
                >
                  {e.name}
                </Link>
              </div>
            </div>
          ),
          sections: [
            {
              id: 'description',
              content: e => <div className="project-description">{e.description}</div>,
            },
          ],
        }}
        empty={
          <Box textAlign="center" color="inherit">
            <Box padding={{ bottom: 's' }} variant="p" color="inherit">
              No projects to display.
            </Box>
          </Box>
        }
        onSelectionChange={projectSelectionHandler}
      />
      <UncheckProjectModal
        projectName={get(deleteProjectModal, 'selectedProject.projectName', '')}
        onDismiss={() => setDeleteProjectModal(deleteProjectModalInitialState)}
        isVisible={get(deleteProjectModal, 'visible', false)}
        onConfirm={onConfirmHandler}
      />
    </SpaceBetween>
  );
};
Projects.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  pageContents: PropTypes.object.isRequired,
  setPageElements: PropTypes.func.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  pushNotification: PropTypes.func,
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pushNotification: setPageNotification,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
