import { createMatchSelector } from 'connected-react-router';
import { getRoutePathWithStudyPeriodPlaceholder } from './route_paths';

export const STUDY_PERIOD = {
  2021: '2021',
  2022: '2022',
  2023: '2023',
};

export const getStudyPeriod = state => {
  const matchSelector = createMatchSelector({
    path: getRoutePathWithStudyPeriodPlaceholder('/:routePath'),
  });
  const match = matchSelector(state);
  return match.params.studyPeriod;
};
