import { useEffect, useState } from 'react';
import { cloneDeep, filter, forEach, get, isEmpty, map, size, isNull, set, max } from 'lodash';
import { sortBy } from 'lodash/collection';
import {
  apiResponses,
  initialState,
  TOOLS_CONTENT_FN,
  validationInitialState,
} from '../../constants/steps-config';
import stepsFn from '../../constants/SurveySteps';
import {
  GET_ACTIVITIES_ENDPOINT,
  GET_EMPLOYEES_FOR_SURVEY_ENDPOINT,
  GET_PROJECT_ALLOCATION,
  GET_PROJECTS_ENDPOINT,
  SUBMIT_SURVEY,
  VALARI_API,
  GET_PROJECT_DOCUMENTS,
  GET_VENDORS_FOR_SURVEY_ENDPOINT,
} from '../../../../common/config/api_endpoints';
import { getAttestationContent } from '../Submit/utils/attestations_list';
import addContextToPayload from '../../../../common/utils/api_util';
import { getRoutePathWithStudyPeriod, ROUTE_PATH } from '../../../../common/constants/route_paths';
import SURVEY_LOCK_ACTIONS from '../../../../common/constants/survey_lock_actions';
import {
  getEntityListPath,
  getSubmitAttestationConfig,
} from '../../../../common/constants/surveyType';
import metricsUtility from '../../../../common/components/metrics/MetricsUtility';
import {
  METRICS_METHOD,
  METRICS_TIMER,
} from '../../../../common/components/metrics/metrics_constants';
import { isReadOnly } from '../../utils/survey_page_utils';

const parser = data => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

export default ({
  closeTools,
  setFormattedToolsContent,
  surveyDetails,
  navigateTo,
  currentDashboard,
  templateId,
  saveSurvey,
  secondaryAssigneesList,
  surveyId,
  studyPeriod,
  updateSurveyLockData,
  updateSurveyLock,
  surveyType,
}) => {
  const steps = stepsFn(templateId);
  const TOOLS_CONTENT = TOOLS_CONTENT_FN(templateId);
  const getDefaultToolsContent = activeIndex => TOOLS_CONTENT[steps[activeIndex].stateKey].default;
  const [pageElements, setPageElements] =
    surveyDetails && size(surveyDetails.secondaryAssignees) !== 0
      ? useState({ ...apiResponses, SecondaryAssignees: surveyDetails.secondaryAssignees })
      : useState(apiResponses);
  const [surveyInfo, setSurveyInfo] =
    surveyDetails && parser(surveyDetails.userResponse) !== null
      ? useState(parser(surveyDetails.userResponse))
      : useState(initialState);
  const { activeStep } = surveyInfo;
  const { validationOnNextButton } = steps[activeStep];
  const [validationState, setValidationState] = useState(validationInitialState);

  // Everytime surveyInfo is updated, we are reordering to follow the order present in
  // defaultSteps. If any key is not present in defaultSteps, they are appended at the end
  // of surveyInfo. While updating the value, we check if new value is different from
  // previous value of surveyInfo, only then we update it to avoid infinite looping.
  useEffect(() => {
    const stepsOrder = steps.map(step => step.stateKey);
    const reorderedStateInfo = Object.fromEntries(
      stepsOrder.filter(key => key in surveyInfo).map(key => [key, surveyInfo[key]]),
    );

    Object.keys(surveyInfo).forEach(key => {
      if (!stepsOrder.includes(key)) {
        reorderedStateInfo[key] = surveyInfo[key];
      }
    });

    if (JSON.stringify(reorderedStateInfo) !== JSON.stringify(surveyInfo)) {
      setSurveyInfo(reorderedStateInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyInfo]);

  useEffect(() => {
    if (isEmpty(surveyDetails)) {
      return;
    }

    metricsUtility.invalidateTimerAndPublish(
      METRICS_METHOD.SURVEY_WIZARD,
      METRICS_TIMER.SURVEY_STEP,
    );

    const { stateKey } = steps[activeStep];
    metricsUtility.startTimer(METRICS_TIMER.SURVEY_STEP, `${stateKey}-Timer`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const setActivityInfo = activityList => {
    setSurveyInfo({
      ...surveyInfo,
      Activity: {
        ...surveyInfo.Activity,
        data: {
          ...get(surveyInfo, 'Activity.data'),
          defaultItems: filter(activityList, ob => ob.isActivityDefault === 'Yes'),
        },
      },
    });
  };

  const attestationConfig = getSubmitAttestationConfig(surveyType);

  useEffect(() => {
    if (Object.keys(surveyDetails).length === 0) return;
    Promise.all([
      addContextToPayload(VALARI_API, GET_EMPLOYEES_FOR_SURVEY_ENDPOINT, studyPeriod, {
        body: {
          surveyId: surveyDetails.surveyId,
        },
      }),
      addContextToPayload(VALARI_API, GET_ACTIVITIES_ENDPOINT, studyPeriod, {
        body: {
          surveyType: surveyDetails.surveyType,
        },
      }),
      addContextToPayload(VALARI_API, GET_PROJECTS_ENDPOINT, studyPeriod, {
        body: {
          surveyId: surveyDetails.surveyId,
        },
      }),
      addContextToPayload(VALARI_API, GET_VENDORS_FOR_SURVEY_ENDPOINT, studyPeriod, {
        body: {
          surveyId: surveyDetails.surveyId,
        },
      }),
    ]).then(r => {
      const activityList = map(
        get(r[1], 'body.activityList', []),
        ({ activityName, activityDescription, isActivityDefault, activityId }) => {
          return {
            name: activityName,
            description: activityDescription,
            id: activityId,
            isActivityDefault,
          };
        },
      );
      const employeeList = sortBy(
        map(parser(get(r[0], 'employeeData', '{}')), data => {
          return {
            ...data,
            key: get(data, 'employeeId', ''),
          };
        }),
        employee => get(employee, 'formattedEmployeeName', ''),
      );
      const projectList = parser(get(r[2], 'body.data', '[]'));
      const vendorList = parser(get(r[3], 'vendorData', '{}'));
      setPageElements({
        ...pageElements,
        Employee: {
          employeeList,
        },
        Activity: {
          activityList,
        },
        Project: {
          projectList,
        },
        Vendor: {
          vendorList,
        },
      });

      const selectedProjectIds = new Set();
      if (isNull(surveyDetails.userResponse) || surveyDetails.userResponse === '') {
        addContextToPayload(VALARI_API, GET_PROJECT_ALLOCATION, studyPeriod, {
          body: {
            employeeIdList: map(employeeList, ({ recordId }) => recordId),
            projectIdList: map(projectList, ({ projectId }) => projectId),
            vendorIdList: map(vendorList, ({ recordId }) => recordId),
          },
        }).then(response => {
          const projectMappings = get(response, 'projectAllocationMappings', null);
          if (projectMappings !== null) {
            forEach(Object.keys(projectMappings), entity => {
              forEach(Object.keys(projectMappings[entity]), projectId => {
                selectedProjectIds.add(projectId);
              });
            });

            const selectedProjects = map(
              filter(projectList, ({ projectId }) => selectedProjectIds.has(projectId)),
              ({ projectId, projectDescription, projectName, isUserCreated }) => {
                return {
                  id: projectId,
                  description: projectDescription,
                  name: projectName,
                  isUserCreated,
                };
              },
            );

            setSurveyInfo({
              ...surveyInfo,
              Activity: {
                data: {
                  defaultItems: filter(activityList, ob => ob.isActivityDefault === 'Yes'),
                },
              },
              Project: {
                data: { selectedProjects },
              },
              ProjectAllocation: { data: projectMappings },
            });
          } else setActivityInfo(activityList);
        });
      } else {
        const employeesPresent = new Set(map(employeeList, ({ recordId }) => recordId));
        const vendorsPresent = new Set(map(vendorList, ({ recordId }) => recordId));
        const projectAllocation = get(surveyInfo, 'ProjectAllocation.data', {});
        const activityAllocation = get(surveyInfo, 'ActivityAllocation.data', {});
        const syncedProjectAllocation = {};
        const syncedActivityAllocation = {};
        forEach(
          filter(
            Object.keys(projectAllocation),
            ob => employeesPresent.has(ob) || vendorsPresent.has(ob),
          ),
          empId => {
            syncedProjectAllocation[empId] = projectAllocation[empId];
          },
        );
        forEach(
          filter(
            Object.keys(activityAllocation),
            ob => employeesPresent.has(ob) || vendorsPresent.has(ob),
          ),
          empId => {
            syncedActivityAllocation[empId] = activityAllocation[empId];
          },
        );

        const selectedProjects = get(surveyInfo, 'Project.data.selectedProjects', []);

        const request = {
          body: {
            projectIdList: selectedProjects.map(project => project.id),
            surveyId,
          },
        };
        addContextToPayload(VALARI_API, GET_PROJECT_DOCUMENTS, studyPeriod, request).then(
          response => {
            const projectDocuments = JSON.parse(get(response, 'body.projectIdToDocumentDataMap'));
            setSurveyInfo({
              ...surveyInfo,
              Activity: {
                ...surveyInfo.Activity,
                data: {
                  ...get(surveyInfo, 'Activity.data'),
                  defaultItems: filter(activityList, ob => ob.isActivityDefault === 'Yes'),
                },
              },
              ProjectAllocation: {
                ...surveyInfo.ProjectAllocation,
                data: syncedProjectAllocation,
              },
              ActivityAllocation: {
                ...surveyInfo.ActivityAllocation,
                data: syncedActivityAllocation,
              },
              ProjectDocuments: {
                data: projectDocuments,
                error: null,
              },
            });
          },
        );
        selectedProjects.forEach(selectedProject => {
          projectList.forEach(project => {
            if (project.projectId === selectedProject.id) {
              set(selectedProject, 'name', project.projectName);
              set(selectedProject, 'description', project.projectDescription);
            }
          });
        });
        setSurveyInfo({
          ...surveyInfo,
          Project: {
            data: { selectedProjects },
          },
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeStateHandler = detail => {
    setSurveyInfo({ ...surveyInfo, [detail.key]: detail.value });
  };

  const setActiveStepIndexAndCloseTools = index => {
    setSurveyInfo({ ...surveyInfo, activeStep: index });
    setFormattedToolsContent(getDefaultToolsContent(index));
    closeTools();
  };

  const validateEmployee = () => {
    return true;
  };

  // const validateVendor = () => {
  //   // TODO = need to add validation here
  //   return true;
  // };

  const validateProjects = () => {
    if (size(get(surveyInfo, 'Project.data.selectedProjects', [])) === 0) return false;
    return true;
  };

  const validateActivities = () => {
    return true;
  };

  const validateProjectDocuments = () => {
    return true;
  };

  const validateActivityAllocation = () => {
    const entityList = get(pageElements, getEntityListPath(surveyType), []);
    const activity = surveyInfo.ActivityAllocation.data;
    if (isEmpty(activity) || Object.keys(activity).length !== entityList.length) return false;
    let validation = true;
    Object.keys(activity).forEach(employee => {
      if (Number(activity[employee].total) !== 100) {
        validation = false;
      }
    });

    return validation;
  };

  const validateProjectAllocation = () => {
    const entityList = get(pageElements, getEntityListPath(surveyType), []);
    const project = surveyInfo.ProjectAllocation.data;
    if (isEmpty(project) || Object.keys(project).length !== entityList.length) return false;
    let validation = true;
    Object.keys(project).forEach(employee => {
      if (Number(project[employee].total) !== 100) {
        validation = false;
      }
    });
    return validation;
  };

  const isAttestationPresent = (submit, attestation) => {
    let isPresent = false;
    forEach(submit, val => {
      if (val === attestation) isPresent = true;
    });
    return isPresent;
  };

  const validateSubmit = () => {
    const submit = surveyInfo.Submit.data;
    if (isEmpty(submit)) return false;
    if (
      isAttestationPresent(
        submit,
        getAttestationContent(attestationConfig.attestationEmployeeListId, surveyType),
      ) &&
      isEmpty(get(surveyInfo, 'Submit.textbox'))
    )
      return false;

    return true;
  };

  const saveSurveyCall = payload => {
    const activeStepContext = get(
      JSON.parse(get(surveyDetails, 'userResponse', '{}')),
      'activeStep',
      0,
    );
    saveSurvey({
      ...payload,
      body: {
        surveyId: surveyDetails.surveyId,
        userResponse: JSON.stringify({
          ...surveyInfo,
          activeStep: max([surveyInfo.activeStep, activeStepContext]),
        }),
        secondaryAssignees: secondaryAssigneesList,
        version: surveyDetails.lastUpdatedOn,
        sessionId: updateSurveyLockData.sessionId,
      },
    });
  };

  const submitCall = () => {
    const request = {
      body: {
        surveyId: surveyDetails.surveyId,
        userResponse: JSON.stringify(surveyInfo),
        secondaryAssignees: secondaryAssigneesList,
        version: surveyDetails.lastUpdatedOn,
        sessionId: updateSurveyLockData.sessionId,
      },
    };
    addContextToPayload(VALARI_API, SUBMIT_SURVEY, studyPeriod, request).then(response => {
      const dashboardPath = get(currentDashboard, 'routePath', ROUTE_PATH.DASHBOARD);
      const navigationState = { pathname: getRoutePathWithStudyPeriod(dashboardPath, studyPeriod) };
      if (response.status === 200) {
        if (dashboardPath === ROUTE_PATH.RND_DASHBOARD)
          navigateTo({
            ...navigationState,
            search: `?surveyId=${surveyId}`,
            state: { submitted: true, surveyId },
          });
        else navigateTo({ ...navigationState, state: { submitted: true, surveyId } });
      } else {
        setSurveyInfo({
          ...surveyInfo,
          SubmitAPI: {
            ...surveyInfo.SubmitAPI,
            error: get(response, 'statusMessage', null),
          },
        });
      }
    });
  };

  // Order of validationSteps in this file and defaultSteps in survey_page/constants/SurveySteps.jsx
  // file should be same.
  const validationSteps = [
    validateEmployee,
    validateActivities,
    validateActivityAllocation,
    validateProjects,
    validateProjectAllocation,
    validateProjectDocuments,
    validateSubmit,
    // TODO: Add vendors in UserResponse
    // validateVendor,
  ];

  const checkErrorOnSubmit = nextStep => {
    const prevStep = surveyInfo.activeStep;
    const pages = Object.keys(surveyInfo);
    const errorPageKey = pages[prevStep];
    setSurveyInfo({
      ...surveyInfo,
      activeStep: nextStep,
      [errorPageKey]: {
        ...surveyInfo[errorPageKey],
        error: !(validationSteps[prevStep] && validationSteps[prevStep]()),
        errorMessage: null,
      },
      SubmitAPI: {
        ...get(surveyInfo, 'SubmitAPI', {}),
        error: null,
      },
    });
  };

  const onNavigate = event => {
    let navigationAllowed = true;
    const { detail } = event;
    const nextStep = detail.requestedStepIndex;
    if (nextStep - activeStep > 0) {
      if (size(validationOnNextButton) !== 0) {
        validationOnNextButton.forEach(validate => {
          navigationAllowed =
            navigationAllowed &&
            validate({
              surveyInfo,
              pageElements,
              setValidationState,
              surveyType,
            });
        });
      }
    }
    if (navigationAllowed) {
      if (isReadOnly(surveyDetails)) {
        checkErrorOnSubmit(nextStep);
      } else {
        saveSurveyCall({
          triggeredByAutosave: false,
          onSuccess: () => {
            checkErrorOnSubmit(nextStep);
          },
        });
      }
    }
  };

  const backToHome = () => {
    const dashboardPath = get(currentDashboard, 'routePath', ROUTE_PATH.DASHBOARD);
    const navigationState = { pathname: getRoutePathWithStudyPeriod(dashboardPath, studyPeriod) };
    if (dashboardPath === ROUTE_PATH.RND_DASHBOARD)
      navigateTo({
        ...navigationState,
        search: `?surveyId=${surveyId}`,
        state: { submitted: false, surveyId },
      });
    else navigateTo({ ...navigationState, state: { submitted: false } });
  };

  const onCancel = () => {
    if (isReadOnly(surveyDetails)) {
      backToHome();
    } else {
      saveSurveyCall({
        triggeredByAutosave: false,
        onSuccess: () => {
          updateSurveyLock({
            onSuccess: () => {
              backToHome();
            },
            body: {
              surveyId,
              actionType: SURVEY_LOCK_ACTIONS.Release,
              sessionId: updateSurveyLockData.sessionId,
            },
          });
        },
      });
    }
  };

  const checkValidation = () => {
    const updated = cloneDeep(surveyInfo);
    let isError = false;

    validationSteps.forEach((step, stepIndex) => {
      const pages = Object.keys(surveyInfo);

      const errorPageKey = pages[stepIndex];
      if (!step()) {
        isError = true;
        updated[errorPageKey] = {
          ...updated[errorPageKey],
          error: true,
        };
      } else {
        updated[errorPageKey] = {
          ...updated[errorPageKey],
          error: false,
        };
      }
    });

    return isError ? updated : null;
  };

  const onSubmit = () => {
    const obj = checkValidation();
    if (obj) {
      setSurveyInfo({ ...obj });
    } else if (isReadOnly(surveyDetails)) {
      backToHome();
    } else {
      submitCall();
    }
  };

  return {
    surveyInfo,
    pageElements,
    setActiveStepIndexAndCloseTools,
    changeStateHandler,
    onNavigate,
    onCancel,
    onSubmit,
    setPageElements,
    validationState,
    setValidationState,
  };
};
